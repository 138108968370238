<template>
  <div>
    <el-form
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        id="id"
        label="活动期数"
        prop="id"
        style="margin-bottom: 35px"
      >
        <el-col :span="12">
          <el-input
            ref="id"
            v-model="dataForm.id"
            maxlength="15"
            placeholder="请输入活动期数"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        id="name"
        label="活动名称"
        prop="name"
        style="margin-bottom: 35px"
      >
        <el-col :span="12">
          <el-input
            ref="name"
            v-model="dataForm.name"
            maxlength="15"
            placeholder="请输入活动名称"
            show-word-limit
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item
        id="desc"
        label="活动描述"
        prop="desc"
        style="margin-bottom: 35px"
      >
        <el-col :span="12">
          <quill-editor
            class="desc_editor"
            ref="descEditor"
            v-model="dataForm.desc"
            :options="descEditorOption"
          >
          </quill-editor>
        </el-col>
      </el-form-item>
      <el-form-item label="投稿时间" prop="submit_time">
        <el-col :span="5">
          <el-form-item prop="submit_start" id="submit_start">
            <el-date-picker
              ref="submit_start"
              type="datetime"
              placeholder="选择投稿开始时间"
              v-model="dataForm.submit_start"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5" id="submit_end">
          <el-form-item prop="submit_end">
            <el-date-picker
              ref="submit_end"
              type="datetime"
              placeholder="选择投稿结束时间"
              v-model="dataForm.submit_end"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="投票时间" prop="vote_time">
        <el-col :span="5">
          <el-form-item prop="vote_start" id="vote_start">
            <el-date-picker
              ref="vote_start"
              type="datetime"
              placeholder="选择投票开始时间"
              v-model="dataForm.vote_start"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5">
          <el-form-item prop="vote_end" id="vote_end">
            <el-date-picker
              ref="vote_end"
              type="datetime"
              placeholder="选择投票结束时间"
              v-model="dataForm.vote_end"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="公示时间" prop="public_time">
        <el-col :span="5">
          <el-form-item prop="public_start" id="public_start">
            <el-date-picker
              ref="public_start"
              type="datetime"
              placeholder="选择公示开始时间"
              v-model="dataForm.public_start"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="5">
          <el-form-item prop="public_end" id="public_end">
            <el-date-picker
              ref="public_end"
              type="datetime"
              placeholder="选择公示结束时间"
              v-model="dataForm.public_end"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>

      <el-form-item label="置顶图" prop="top_image" id="top_image">
        <el-upload
          class="avatar-uploader"
          :show-file-list="false"
          :before-upload="beforeTopImageUpload"
          action="#"
          :http-request="httpUploadRequest"
        >
          <img
            v-if="dataForm.top_image"
            :src="dataForm.top_image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="投票设置">
        <el-col :span="2" style="text-align: right; padding-right: 12px">
          <span style="color: #606266">用户有效票数</span>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="vote_setting.count" id="vote_setting.count">
            <el-input
              ref="vote_setting_count"
              type="number"
              v-model="dataForm.vote_setting.count"
              placeholder="请选择有效票数，-1为不限制"
              style="width: 200px"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="投票刷新时间"
            prop="vote_setting.type"
            label-width="200"
            style="margin-left: 20px"
          >
            <el-select
              v-model="dataForm.vote_setting.type"
              style="width: 200px"
            >
              <el-option :value="0" label="不刷新"></el-option>
              <el-option :value="1" label="每日刷新"></el-option>
              <el-option :value="2" label="每周刷新"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-form-item>

      <el-form-item label="奖项设置" prop="prize">
        <el-button
          type="primary"
          @click="handleAddPrize"
          v-if="view_type == 'add' || view_type == 'edit'"
          >添加奖项</el-button
        >
        <el-table
          :data="dataForm.prize"
          style="margin-top:20px"
          :header-cell-style="{ background: '#409EFF' }"
          border
        >
          <el-table-column prop="name" label="奖项名称"> </el-table-column>
          <el-table-column prop="content" label="奖项内容"> </el-table-column>
          <el-table-column
            label="操作"
            fixed="right"
            width="150"
            v-if="view_type == 'add' || view_type == 'edit'"
          >
            <template slot-scope="scope">
              <el-button @click="handleEditPrize(scope.row)">编辑</el-button>
              <popconfirmButton
                content="是否确认删除？"
                text="删除"
                @onConfirm="handleDeletePrize(scope.row)"
                style="margin-left: 10px"
              ></popconfirmButton>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>

      <el-form-item label="评审设置" prop="reviewer">
        <el-button
          type="primary"
          @click="handleAddReviewer"
          v-if="view_type == 'add' || view_type == 'edit'"
          >添加评审</el-button
        >
        <el-table
          :data="dataForm.reviewer"
          style="margin-top:20px"
          :header-cell-style="{ background: '#409EFF' }"
          border
        >
          <el-table-column label="头像">
            <template slot-scope="scope">
              <img
                style="border-radius: 50px; width: 100px; height: 100px"
                :src="scope.row.avatar"
              />
            </template>
          </el-table-column>
          <el-table-column prop="name" label="昵称"> </el-table-column>
          <el-table-column prop="desc" label="简介"> </el-table-column>
          <el-table-column
            label="操作"
            fixed="right"
            width="150"
            v-if="view_type == 'add' || view_type == 'edit'"
          >
            <template slot-scope="scope">
              <el-button @click="handleEditReviewer(scope.row)">编辑</el-button>
              <popconfirmButton
                content="是否确认删除？"
                text="删除"
                @onConfirm="handleDeleteReviewer(scope.row)"
                style="margin-left: 10px"
              ></popconfirmButton>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item
        label="活动规则"
        prop="rule_desc"
        id="quillEditorQiniu"
        style="height: 787px"
      >
        <!-- 基于elementUi的上传组件 el-upload begin-->
        <el-upload
          class="avatar-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0;"
          :http-request="httpUploadEditorRequest"
        >
        </el-upload>

        <el-upload
          class="bg-uploader"
          :accept="'image/*'"
          :show-file-list="false"
          action="#"
          style="height: 0;"
          :http-request="httpUploadBgEditorRequest"
        >
        </el-upload>
        <!-- 基于elementUi的上传组件 el-upload end-->
        <quill-editor
          class="editor ql-editor"
          v-model="dataForm.rule_desc"
          ref="customQuillEditor"
          :options="editorOption"
        >
        </quill-editor>
      </el-form-item>
      <el-form-item style="margin-top: 50px; text-align: center">
        <perButton
          type="primary"
          perm="activity/save"
          @click="saveDraft()"
          size="large"
          >保存草稿</perButton
        >
        <perButton
          type="primary"
          perm="activity/save"
          @click="submitForm()"
          size="large"
          >提交</perButton
        >
        <perButton
          type="default"
          @click="
            previewVisible = true;
            activity_data_preview = {};
            activity_data_preview = dataForm;
          "
          size="large"
          >预览</perButton
        >
      </el-form-item>
    </el-form>

    <el-dialog
      :title="prizeAction == 0 ? '编辑' : '新增'"
      :visible.sync="prizeVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="prizeForm"
        :model="prizeParams"
        label-width="80px"
        :rules="prizeRules"
      >
        <el-form-item label="奖项名称" prop="name">
          <el-input
            v-model="prizeParams.name"
            placeholder="请输入奖项名称"
            maxlength="8"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="奖项内容" prop="content">
          <quill-editor
            class="prize_editor"
            ref="prizeEditor"
            v-model="prizeParams.content"
            :options="prizeEditorOption"
          >
          </quill-editor>
        </el-form-item>
        <el-form-item label="背景图" prop="image">
          <el-upload
            style="width: 161px; height: 120px"
            class="avatar-uploader"
            :show-file-list="false"
            action="#"
            :before-upload="beforePrizeImageUpload"
            :http-request="httpUploadPrizeRequest"
          >
            <img
              v-if="prizeParams.image"
              :src="prizeParams.image"
              class="avatar"
              style="width: 161px; height: 120px"
            />
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
              style="width: 161px; height: 120px; line-height: 120px"
            ></i>
          </el-upload>
          <p>上传图片尺寸630*200</p>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="changePrize">确定</el-button>
          <el-button @click="cancelDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      :title="reviewerAction == 0 ? '编辑' : '新增'"
      :visible.sync="reviewerVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="reviewerForm"
        :model="reviewerParams"
        label-width="80px"
        :rules="reviewerRules"
      >
        <el-form-item label="昵称" prop="name">
          <el-input
            v-model="reviewerParams.name"
            placeholder="请输入昵称"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="头像" prop="avatar">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            action="#"
            :before-upload="beforeReviewerImageUpload"
            :http-request="httpUploadReviewerRequest"
          >
            <img
              v-if="reviewerParams.avatar"
              :src="reviewerParams.avatar"
              class="avatar"
              style="width: 150px; height: 150px"
            />
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
              style="width: 150px; height: 150px; line-height: 150px"
            ></i>
          </el-upload>
          <p>上传图片尺寸300*300</p>
        </el-form-item>
        <el-form-item label="简介" prop="desc">
          <el-input
            v-model="reviewerParams.desc"
            :rows="2"
            type="textarea"
            maxlength="30"
            placeholder="请输入简介"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="changeReviewer">确定</el-button>
          <el-button @click="cancelDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="'预览'"
      :visible.sync="previewVisible"
      v-if="previewVisible"
      width="fit-content"
      :close-on-click-modal="false"
    >
      <preview_activity
        :activity_data="activity_data_preview"
      ></preview_activity>
    </el-dialog>
  </div>
</template>

<script>
import { uploadOssUrl } from "@/utils/uploadUrl";
import perButton from "@/components/perm/perButton";
import preview_activity from "./preview_activity";
// import * as Quill from 'quill';
// let fontSizeStyle = Quill.import('attributors/style/size');
// let fontSizeStyle = Quill.import('attributors/style/size');
// let sizes = [];
// for (let i = 10; i < 51; i++) {
//   sizes.push(i + "px");
// }
//
// for (let i = 18; i < 73; i++) {
//   sizes.push(i + "rpx");
// }
//
// fontSizeStyle.whitelist = sizes;

import { randomStr } from "@/utils/randomStr";
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ indent: "-1" }, { indent: "+1" }],
  [{ size: ["20rpx", "24rpx", "26rpx", "32rpx", "48rpx", "custom-size"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [
    {
      color: [
        "#000000",
        "#e60000",
        "#ff9900",
        "#ffff00",
        "#008a00",
        "#0066cc",
        "#9933ff",
        "#ffffff",
        "#facccc",
        "#ffebcc",
        "#ffffcc",
        "#cce8cc",
        "#cce0f5",
        "#ebd6ff",
        "#bbbbbb",
        "#f06666",
        "#ffc266",
        "#ffff66",
        "#66b966",
        "#66a3e0",
        "#c285ff",
        "#888888",
        "#a10000",
        "#b26b00",
        "#b2b200",
        "#006100",
        "#0047b2",
        "#6b24b2",
        "#444444",
        "#5c0000",
        "#663d00",
        "#666600",
        "#003700",
        "#002966",
        "#3d1466",
        "custom-color",
      ],
    },
    { background: [] },
  ], // dropdown with defaults from theme
  [{ align: [] }],
  ["image"],
  ["clean"], // remove formatting button
];

const textToolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["20rpx", "24rpx", "26rpx", "32rpx", "48rpx", "custom-size"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [
    {
      color: [
        "#000000",
        "#e60000",
        "#ff9900",
        "#ffff00",
        "#008a00",
        "#0066cc",
        "#9933ff",
        "#ffffff",
        "#facccc",
        "#ffebcc",
        "#ffffcc",
        "#cce8cc",
        "#cce0f5",
        "#ebd6ff",
        "#bbbbbb",
        "#f06666",
        "#ffc266",
        "#ffff66",
        "#66b966",
        "#66a3e0",
        "#c285ff",
        "#888888",
        "#a10000",
        "#b26b00",
        "#b2b200",
        "#006100",
        "#0047b2",
        "#6b24b2",
        "#444444",
        "#5c0000",
        "#663d00",
        "#666600",
        "#003700",
        "#002966",
        "#3d1466",
        "custom-color",
      ],
    },
    { background: [] },
  ], // dropdown with defaults from theme
  [{ align: [] }],
  ["clean"], // remove formatting button
];

import { mapActions } from "vuex";
export default {
  name: "activity_change",
  components: {
    perButton,
    preview_activity,
  },
  props: {
    activity_data: {
      type: Object,
    },
    view_type: {
      type: String,
      default: "add",
    },
  },
  watch: {
    activity_data: {
      handler: function(val) {
        this.setFormData();
      },
      immediate: true,
    },
  },
  data() {
    let self = this;
    const submitEndTimeCheckRule = (rule, value, callback) => {
      if (self.dataForm.submit_start !== "" && self.dataForm.submit_end != "") {
        let submit_start = new Date(self.dataForm.submit_start);
        let submit_end = new Date(self.dataForm.submit_end);
        if (submit_end <= submit_start) {
          callback(new Error("结束时间必须大于开始时间"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    const voteStartTimeCheckRule = (rule, value, callback) => {
      if (self.dataForm.submit_end != "" && self.dataForm.vote_start != "") {
        let submit_end = new Date(self.dataForm.submit_end);
        let vote_start = new Date(self.dataForm.vote_start);
        if (vote_start < submit_end) {
          callback(new Error("投票开始时间必须大于等于投稿结束时间"));
          return;
        }
      }

      callback();
    };

    const voteEndTimeCheckRule = (rule, value, callback) => {
      if (self.dataForm.vote_start != "" && self.dataForm.vote_end != "") {
        let vote_start = new Date(self.dataForm.vote_start);
        let vote_end = new Date(self.dataForm.vote_end);
        if (vote_end <= vote_start) {
          callback(new Error("结束时间必须大于开始时间"));
          return;
        }
      }

      callback();
    };

    const publicStartTimeCheckRule = (rule, value, callback) => {
      if (self.dataForm.vote_end != "" && self.dataForm.public_start != "") {
        let vote_end = new Date(self.dataForm.vote_end);
        let public_start = new Date(self.dataForm.public_start);
        if (public_start < vote_end) {
          callback(new Error("公示开始时间必须大于等于投票结束时间"));
          return;
        }
      }

      callback();
    };

    const publicEndTimeCheckRule = (rule, value, callback) => {
      if (self.dataForm.public_start != "" && self.dataForm.public_end != "") {
        let public_start = new Date(self.dataForm.public_start);
        let public_end = new Date(self.dataForm.public_end);
        if (public_end <= public_start) {
          callback(new Error("结束时间必须大于开始时间"));
          return;
        }
      }

      callback();
    };

    var validatorVoteCount = (rule, value, callback) => {
      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于等于-1的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > -2) {
        callback();
      } else {
        callback(new Error("请输入大于等于-1的整数"));
      }
    };

    return {
      activity_data_preview: {},
      dataForm: {
        id: "",
        name: "",
        desc: "",
        submit_start: "",
        submit_end: "",
        vote_start: "",
        vote_end: "",
        public_start: "",
        public_end: "",
        top_image: "",
        vote_setting: {
          count: -1,
          type: 0,
        },
        rule_desc: "",
        rule_desc_url: "",
        prize: [],
        reviewer: [],
      },
      prizeParams: {
        name: "",
        content: "",
        image: "",
      },
      prizeRow: {},
      prizeVisible: false,
      prizeImageValid: false,
      prizeAction: 0,
      prizeRules: {
        name: [
          { required: true, message: "请输入奖项名称", trigger: "change" },
          {
            min: 1,
            max: 8,
            message: "长度在 1 到 8 个字符",
            trigger: "change",
          },
        ],
        content: [
          { required: true, message: "请输入奖项内容", trigger: "change" },
        ],
        image: [
          { required: true, message: "请输入奖项背景图片", trigger: "change" },
        ],
      },
      reviewerParams: {
        name: "",
        desc: "",
        avatar: "",
      },
      reviewerRow: {},
      reviewerVisible: false,
      reviewerImageValid: false,
      reviewerAction: 0,
      reviewerRules: {
        name: [
          { required: true, message: "请输入昵称", trigger: "change" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "change",
          },
        ],
        desc: [
          { required: true, message: "请输入简介", trigger: "change" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "change",
          },
        ],
        avatar: [{ required: true, message: "请选择头像", trigger: "change" }],
      },
      rules: {
        id: [
          { required: true, message: "请输入期数", trigger: "blur" },
          { min: 1, max: 15, message: "长度在 1 到 5 个字符", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 1, max: 15, message: "长度在 1 到 5 个字符", trigger: "blur" },
        ],
        desc: [{ required: true, message: "请输入活动描述", trigger: "blur" }],
        top_image: [
          { required: true, message: "请选择置顶图", trigger: "change" },
        ],
        submit_start: [
          {
            type: "date",
            required: true,
            message: "请选择投稿开始日期",
            trigger: ["change", "blur"],
          },
        ],
        submit_end: [
          {
            type: "date",
            required: true,
            message: "请选择投稿结束日期",
            trigger: ["change", "blur"],
          },
          { validator: submitEndTimeCheckRule, trigger: ["change", "blur"] },
        ],
        vote_start: [
          {
            type: "date",
            required: true,
            message: "请选择投票开始日期",
            trigger: ["change", "blur"],
          },
          { validator: voteStartTimeCheckRule, trigger: ["change", "blur"] },
        ],
        vote_end: [
          {
            type: "date",
            required: true,
            message: "请选择投票结束日期",
            trigger: ["change", "blur"],
          },
          { validator: voteEndTimeCheckRule, trigger: ["change", "blur"] },
        ],
        public_start: [
          {
            type: "date",
            required: true,
            message: "请选择公示开始日期",
            trigger: ["change", "blur"],
          },
          { validator: publicStartTimeCheckRule, trigger: ["change", "blur"] },
        ],
        public_end: [
          {
            type: "date",
            required: true,
            message: "请选择公示结束日期",
            trigger: ["change", "blur"],
          },
          { validator: publicEndTimeCheckRule, trigger: ["change", "blur"] },
        ],
        "vote_setting.count": [
          { required: true, message: "请输入投票次数", trigger: "change" },
          { validator: validatorVoteCount, trigger: ["change", "blur"] },
        ],
        rule_desc: [
          { required: true, message: "请输入活动规则", trigger: "change" },
        ],

        // prize: [
        //   { type: 'array', required: true, message: '请至少添加一项奖项', trigger: 'change' }
        // ],
        // reviewer: [
        //   { type: 'array', required: true, message: '请至少添加一项奖项', trigger: 'change' }
        // ]
      },
      descEditorOption: {
        placeholder: "请填写活动说明？",
        modules: {
          toolbar: {
            container: textToolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function(value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
            },
          },
        },
      },
      prizeEditorOption: {
        placeholder: "请填写奖项内容？",
        modules: {
          toolbar: {
            container: textToolbarOptions, // 工具栏
            handlers: {
              size: function(value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function(value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
            },
          },
        },
      },
      editorOption: {
        placeholder: "请填写活动规则？",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              "background-image": function(value) {
                // 选择图片的处理函数，获取到图片url
                const url = "https://example.com/xxx.jpg";
                this.quill.format(
                  "background-image",
                  `url(${url}) center no-repeat`
                );
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .bg-uploader input")
                    .click();
                } else {
                  this.quill.format("background-image", false);
                }
              },
              size: function(value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function(value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function(value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function(value) {
                if (value) {
                  document
                    .querySelector("#quillEditorQiniu .avatar-uploader input")
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      previewVisible: false,
      loading: false,
    };
  },
  mounted() {
    this.setFormData();
  },
  methods: {
    setFormData() {
      if (this.activity_data && this.activity_data._id) {
        Object.keys(this.dataForm).forEach((key) => {
          if (this.activity_data[key]) {
            this.dataForm[key] = this.activity_data[key];
          }
        });

        this.dataForm.submit_start = this.getDateTime(
          this.activity_data.submit_start || ""
        );
        this.dataForm.submit_end = this.getDateTime(
          this.activity_data.submit_end || ""
        );
        this.dataForm.vote_start = this.getDateTime(
          this.activity_data.vote_start || ""
        );
        this.dataForm.vote_end = this.getDateTime(
          this.activity_data.vote_end || ""
        );
        this.dataForm.public_start = this.getDateTime(
          this.activity_data.public_start || ""
        );
        this.dataForm.public_end = this.getDateTime(
          this.activity_data.public_end || ""
        );

        if (this.activity_data.vote_setting) {
          this.dataForm.vote_setting = {
            count: this.activity_data.vote_setting.count,
            type: this.activity_data.vote_setting.type,
          };
        }

        if (this.activity_data.prize) {
          this.dataForm.prize = this.activity_data.prize.map((r) => {
            return {
              name: r.name,
              content: r.content,
              image: r.image,
            };
          });
        }

        if (this.activity_data.reviewer) {
          this.dataForm.reviewer = this.activity_data.reviewer.map((r) => {
            return {
              name: r.name,
              desc: r.desc,
              avatar: r.avatar,
            };
          });
        }
      } else {
        this.dataForm = {
          id: "",
          name: "",
          desc: "",
          submit_start: "",
          submit_end: "",
          vote_start: "",
          vote_end: "",
          public_start: "",
          public_end: "",
          top_image: "",
          vote_setting: {
            count: -1,
            type: 0,
          },
          rule_desc: "",
          rule_desc_url: "",
          prize: [],
          reviewer: [],
        };
      }
    },
    getDateTime(dataStr) {
      if (dataStr && dataStr.length) {
        return new Date(dataStr);
      }

      return "";
    },
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          let d = this.getSaveData();
          let { data, errorCode } = await this.$http.saveActivity(d);
          if (errorCode != "0000") {
            this.$message.error("保存失败");
            return;
          }

          this.$message({
            type: "success",
            message: "保存成功",
          });

          this.removeTabByPath("/activities/add_activity");
          this.removeTabByPath("/activities/edit_activity");
          this.$router.push("/activities/activities");
          this.$bus.$emit("onActivityChanged", data);

          // if(this.view_type == "add") {
          //   this.removeTabByPath('/activities/add_activity');
          //   this.removeTabByPath('/activities/edit_activity');
          //   this.$router.push({
          //     path: '/activities/edit_activity',
          //     query: {'_id': data._id}
          //   });
          // }
        } else {
          console.log("error submit!!");
          console.log(invalidFields);
          let fileds = Object.keys(invalidFields);
          if (fileds.length) {
            let id1 = fileds[0];
            if (id1 == "rule_desc") {
              id1 = "quillEditorQiniu";
            }

            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: "smooth" });
            }

            let refKey = id1.replace(/\./g, "_");
            if (this.$refs[refKey]) {
              this.$refs[refKey].focus();
            }
          }
          return false;
        }
      });
    },
    async saveDraft() {
      let d = this.getSaveData();
      let { data, errorCode } = await this.$http.saveActivityAsDraft(d);
      if (errorCode != "0000") {
        this.$message.error("保存失败");
        return;
      }

      this.$message({
        type: "success",
        message: "保存成功",
      });

      this.removeTabByPath("/activities/add_activity");
      this.removeTabByPath("/activities/edit_activity");
      this.$router.push("/activities/activities");
      this.$bus.$emit("onActivityChanged", data);

      // if(this.view_type == "add") {
      //   this.removeTabByPath('/activities/add_activity');
      //   this.removeTabByPath('/activities/edit_activity');
      //   this.$router.push({
      //     path: '/activities/edit_activity',
      //     query: {'_id': data._id}
      //   });
      //
      //   this.$bus.$emit('onActivityChanged', data);
      // }
    },
    getSaveData() {
      let data = {
        ...this.dataForm,
        action: this.view_type == "edit" ? 1 : 0,
        _id: "",
      };

      if (this.view_type == "edit") {
        data._id = this.activity_data._id;
      }

      return data;
    },
    resetForm() {
      this.$refs.dataForm.resetFields();
    },
    handleAddPrize() {
      this.prizeVisible = true;
      this.prizeAction = 1;
      this.prizeRow = {};
      this.prizeParams = {
        name: "",
        content: "",
        image: "",
      };
      // this.$refs.prizeForm.resetFields();
    },
    handleEditPrize(row) {
      this.prizeVisible = true;
      this.prizeAction = 0;
      this.prizeRow = row;
      // this.$refs.prizeForm.resetFields();
      this.prizeParams = {
        name: row.name,
        content: row.content,
        image: row.image,
      };
    },
    handleDeletePrize(row) {
      let index = this.dataForm.prize.indexOf(row);
      if (index >= 0) {
        this.dataForm.prize.splice(index, 1);
      }
    },
    handleAddReviewer() {
      this.reviewerVisible = true;
      this.reviewerAction = 1;
      this.reviewerParams = {
        name: "",
        desc: "",
        avatar: "",
      };
      // this.$refs.reviewerForm.resetFields();
    },
    handleEditReviewer(row) {
      this.reviewerVisible = true;
      this.reviewerAction = 0;
      // this.$refs.reviewerForm.resetFields();
      this.reviewerRow = row;
      this.reviewerParams = {
        name: row.name,
        desc: row.desc,
        avatar: row.avatar,
      };
    },
    handleDeleteReviewer(row) {
      let index = this.dataForm.reviewer.indexOf(row);
      if (index >= 0) {
        this.dataForm.reviewer.splice(index, 1);
      }
    },
    changePrize() {
      this.$refs.prizeForm.validate((valid) => {
        if (valid) {
          if (this.prizeAction == 0) {
            this.prizeRow.name = this.prizeParams.name;
            this.prizeRow.content = this.prizeParams.content;
            this.prizeRow.image = this.prizeParams.image;
          } else {
            this.dataForm.prize.push({
              name: this.prizeParams.name,
              content: this.prizeParams.content,
              image: this.prizeParams.image,
            });
          }
          this.prizeVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeReviewer() {
      this.$refs.reviewerForm.validate((valid) => {
        if (valid) {
          if (this.reviewerAction == 0) {
            this.reviewerRow.name = this.reviewerParams.name;
            this.reviewerRow.desc = this.reviewerParams.desc;
            this.reviewerRow.avatar = this.reviewerParams.avatar;
          } else {
            this.dataForm.reviewer.push({
              name: this.reviewerParams.name,
              desc: this.reviewerParams.desc,
              avatar: this.reviewerParams.avatar,
            });
          }
          this.reviewerVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    cancelDialog() {
      this.prizeVisible = false;
      this.reviewerVisible = false;
    },
    beforeTopImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },
    async httpUploadRequest(item) {
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().activity_img + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.dataForm.top_image = result;
      } else {
        this.$message.error("上传失败");
      }
    },
    async beforePrizeImageUpload(file) {
      this.prizeImageValid = false;
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      let self = this;
      let width = 322;
      let height = 240;
      let sizeRes = await new Promise(function(resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = (e) => {
          let src = e.target.result;
          const image = new Image();
          image.onload = function() {
            if (this.width != width || (height && this.height != height)) {
              self.$message.error(
                "请上传宽为" + width + "px,高为" + height + "px的图片"
              );
              resolve(false);
            } else {
              resolve(true);
            }
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });

      let res = isJPG && isLt2M && sizeRes;
      this.prizeImageValid = true;
      return res;
    },
    async httpUploadPrizeRequest(item) {
      if (!this.prizeImageValid) {
        return;
      }

      let fileName = randomStr() + ".jpg";
      uploadOssUrl().activity_img;
      let path = uploadOssUrl().activity_prize + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.prizeParams.image = result;
      } else {
        this.$message.error("上传失败");
      }
    },
    async beforeReviewerImageUpload(file) {
      this.reviewerImageValid = false;
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      let self = this;
      let width = 300;
      let height = 300;
      let sizeRes = await new Promise(function(resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = (e) => {
          let src = e.target.result;
          const image = new Image();
          image.onload = function() {
            if (this.width != width || (height && this.height != height)) {
              self.$message.error(
                "请上传宽为" + width + "px，高为" + height + "px的图片"
              );
              resolve(false);
            } else {
              resolve(true);
            }
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });

      let res = isJPG && isLt2M && sizeRes;
      this.reviewerImageValid = true;
      return res;
    },
    async httpUploadReviewerRequest(item) {
      if (!this.reviewerImageValid) {
        return;
      }
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().activity_prize + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.reviewerParams.avatar = result;
      } else {
        this.$message.error("上传失败");
      }
    },
    async httpUploadBgEditorRequest(item) {
      let self = this;
      let fileName = randomStr() + ".jpg";
      let path = +fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(imgUrl);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditor.quill;
        // 获取光标所在位置
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          //quill.insertEmbed(length, 'video', imgUrl)
        } else {
          quill.format("background-image", `url(${imgUrl}) center no-repeat`);
        }
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }
    },
    async httpUploadEditorRequest(item) {
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().activity_rule + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(type);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditor.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type == "mp4" || type == "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>

<style scoped>
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
  margin-bottom: 50px;
  padding: 0 !important;
}

/deep/.editor .ql-editor {
  height: 667px;
}

.desc_editor {
  ine-height: normal !important;
  height: 200px;
  width: 100%;
  margin-bottom: 90px;
}
.prize_editor {
  ine-height: normal !important;
  height: 200px;
  width: 100%;
  margin-bottom: 90px;
}
/deep/ .ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
/deep/ .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

/deep/ .ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

/deep/ .ql-editor .ql-size-10px {
  font-size: 10px;
}
/deep/ .ql-editor .ql-size-11px {
  font-size: 11px;
}
/deep/ .ql-editor .ql-size-12px {
  font-size: 12px;
}
/deep/ .ql-editor .ql-size-13px {
  font-size: 13px;
}
/deep/ .ql-editor .ql-size-14px {
  font-size: 14px;
}
/deep/ .ql-editor .ql-size-15px {
  font-size: 15px;
}
/deep/ .ql-editor .ql-size-16px {
  font-size: 16px;
}
/deep/ .ql-editor .ql-size-17px {
  font-size: 17px;
}
/deep/ .ql-editor .ql-size-18px {
  font-size: 18px;
}
/deep/ .ql-editor .ql-size-19px {
  font-size: 19px;
}
/deep/ .ql-editor .ql-size-20px {
  font-size: 20px;
}
/deep/ .ql-editor .ql-size-21px {
  font-size: 21px;
}
/deep/ .ql-editor .ql-size-22px {
  font-size: 22px;
}
/deep/ .ql-editor .ql-size-23px {
  font-size: 23px;
}
/deep/ .ql-editor .ql-size-24px {
  font-size: 24px;
}
/deep/ .ql-editor .ql-size-25px {
  font-size: 25px;
}
/deep/ .ql-editor .ql-size-26px {
  font-size: 26px;
}
/deep/ .ql-editor .ql-size-27px {
  font-size: 27px;
}
/deep/ .ql-editor .ql-size-28px {
  font-size: 28px;
}
/deep/ .ql-editor .ql-size-29px {
  font-size: 29px;
}
/deep/ .ql-editor .ql-size-30px {
  font-size: 30px;
}
/deep/ .ql-editor .ql-size-31px {
  font-size: 31px;
}
/deep/ .ql-editor .ql-size-32px {
  font-size: 32px;
}
/deep/ .ql-editor .ql-size-33px {
  font-size: 33px;
}
/deep/ .ql-editor .ql-size-34px {
  font-size: 34px;
}
/deep/ .ql-editor .ql-size-35px {
  font-size: 35px;
}
/deep/ .ql-editor .ql-size-36px {
  font-size: 36px;
}
/deep/ .ql-editor .ql-size-37px {
  font-size: 37px;
}
/deep/ .ql-editor .ql-size-38px {
  font-size: 38px;
}
/deep/ .ql-editor .ql-size-39px {
  font-size: 39px;
}
/deep/ .ql-editor .ql-size-40px {
  font-size: 40px;
}
/deep/ .ql-editor .ql-size-41px {
  font-size: 41px;
}
/deep/ .ql-editor .ql-size-42px {
  font-size: 42px;
}
/deep/ .ql-editor .ql-size-43px {
  font-size: 43px;
}
/deep/ .ql-editor .ql-size-44px {
  font-size: 44px;
}
/deep/ .ql-editor .ql-size-45px {
  font-size: 45px;
}
/deep/ .ql-editor .ql-size-46px {
  font-size: 46px;
}
/deep/ .ql-editor .ql-size-47px {
  font-size: 47px;
}
/deep/ .ql-editor .ql-size-48px {
  font-size: 48px;
}
/deep/ .ql-editor .ql-size-49px {
  font-size: 49px;
}
/deep/ .ql-editor .ql-size-50px {
  font-size: 50px;
}

/deep/ .ql-editor .ql-size-18rpx {
  font-size: 9px;
}
/deep/ .ql-editor .ql-size-19rpx {
  font-size: 9px;
}
/deep/ .ql-editor .ql-size-20rpx {
  font-size: 10px;
}
/deep/ .ql-editor .ql-size-21rpx {
  font-size: 10px;
}
/deep/ .ql-editor .ql-size-22rpx {
  font-size: 11px;
}
/deep/ .ql-editor .ql-size-23rpx {
  font-size: 11px;
}
/deep/ .ql-editor .ql-size-24rpx {
  font-size: 12px;
}
/deep/ .ql-editor .ql-size-25rpx {
  font-size: 12px;
}
/deep/ .ql-editor .ql-size-26rpx {
  font-size: 13px;
}
/deep/ .ql-editor .ql-size-27rpx {
  font-size: 13px;
}
/deep/ .ql-editor .ql-size-28rpx {
  font-size: 14px;
}
/deep/ .ql-editor .ql-size-29rpx {
  font-size: 14px;
}
/deep/ .ql-editor .ql-size-30rpx {
  font-size: 15px;
}
/deep/ .ql-editor .ql-size-31rpx {
  font-size: 15px;
}
/deep/ .ql-editor .ql-size-32rpx {
  font-size: 16px;
}
/deep/ .ql-editor .ql-size-33rpx {
  font-size: 16px;
}
/deep/ .ql-editor .ql-size-34rpx {
  font-size: 17px;
}
/deep/ .ql-editor .ql-size-35rpx {
  font-size: 17px;
}
/deep/ .ql-editor .ql-size-36rpx {
  font-size: 18px;
}
/deep/ .ql-editor .ql-size-37rpx {
  font-size: 18px;
}
/deep/ .ql-editor .ql-size-38rpx {
  font-size: 19px;
}
/deep/ .ql-editor .ql-size-39rpx {
  font-size: 19px;
}
/deep/ .ql-editor .ql-size-40rpx {
  font-size: 20px;
}
/deep/ .ql-editor .ql-size-41rpx {
  font-size: 20px;
}
/deep/ .ql-editor .ql-size-42rpx {
  font-size: 21px;
}
/deep/ .ql-editor .ql-size-43rpx {
  font-size: 21px;
}
/deep/ .ql-editor .ql-size-44rpx {
  font-size: 22px;
}
/deep/ .ql-editor .ql-size-45rpx {
  font-size: 22px;
}
/deep/ .ql-editor .ql-size-46rpx {
  font-size: 23px;
}
/deep/ .ql-editor .ql-size-47rpx {
  font-size: 23px;
}
/deep/ .ql-editor .ql-size-48rpx {
  font-size: 24px;
}
/deep/ .ql-editor .ql-size-49rpx {
  font-size: 24px;
}
/deep/ .ql-editor .ql-size-50rpx {
  font-size: 25px;
}
/deep/ .ql-editor .ql-size-51rpx {
  font-size: 25px;
}
/deep/ .ql-editor .ql-size-52rpx {
  font-size: 26px;
}
/deep/ .ql-editor .ql-size-53rpx {
  font-size: 26px;
}
/deep/ .ql-editor .ql-size-54rpx {
  font-size: 27px;
}
/deep/ .ql-editor .ql-size-55rpx {
  font-size: 27px;
}
/deep/ .ql-editor .ql-size-56rpx {
  font-size: 28px;
}
/deep/ .ql-editor .ql-size-57rpx {
  font-size: 28px;
}
/deep/ .ql-editor .ql-size-58rpx {
  font-size: 29px;
}
/deep/ .ql-editor .ql-size-59rpx {
  font-size: 29px;
}
/deep/ .ql-editor .ql-size-60rpx {
  font-size: 30px;
}
/deep/ .ql-editor .ql-size-61rpx {
  font-size: 30px;
}
/deep/ .ql-editor .ql-size-62rpx {
  font-size: 31px;
}
/deep/ .ql-editor .ql-size-63rpx {
  font-size: 31px;
}
/deep/ .ql-editor .ql-size-64rpx {
  font-size: 32px;
}
/deep/ .ql-editor .ql-size-65rpx {
  font-size: 32px;
}
/deep/ .ql-editor .ql-size-66rpx {
  font-size: 33px;
}
/deep/ .ql-editor .ql-size-67rpx {
  font-size: 33px;
}
/deep/ .ql-editor .ql-size-68rpx {
  font-size: 34px;
}
/deep/ .ql-editor .ql-size-69rpx {
  font-size: 34px;
}
/deep/ .ql-editor .ql-size-70rpx {
  font-size: 35px;
}
/deep/ .ql-editor .ql-size-71rpx {
  font-size: 35px;
}
/deep/ .ql-editor .ql-size-72rpx {
  font-size: 36px;
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "32px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="48px"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
  content: "48px";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20rpx"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20rpx"]::before {
  content: "20rpx";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24rpx"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24rpx"]::before {
  content: "24rpx";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26rpx"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26rpx"]::before {
  content: "26rpx";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32rpx"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32rpx"]::before {
  content: "32rpx";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="48rpx"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48rpx"]::before {
  content: "48rpx";
}

/deep/
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-label[data-value="custom-size"]::before,
/deep/
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value="custom-size"]::before {
  content: "自定义";
}

/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

/deep/ .ql-snow .ql-picker.ql-font .ql-picker-label::before,
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
/deep/
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="monospace"]::before,
/deep/
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ #quillEditorQiniu .el-form-item__content {
  height: 100%;
}

/deep/ .ql-picker-item[data-value="custom-color"] {
  background: transparent !important;
  background-color: transparent !important;
  width: fit-content;
  height: fit-content;
}

/deep/ .ql-picker-item[data-value="custom-color"]::before {
  content: "自定义颜色";
  color: black;
  width: fit-content;
}
</style>
