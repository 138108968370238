import { render, staticRenderFns } from "./activity_change.vue?vue&type=template&id=6a67da46&scoped=true&"
import script from "./activity_change.vue?vue&type=script&lang=js&"
export * from "./activity_change.vue?vue&type=script&lang=js&"
import style0 from "./activity_change.vue?vue&type=style&index=0&id=6a67da46&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-compiler@2.7.14_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a67da46",
  null
  
)

export default component.exports